
$white:    #ffffff;
$gray-100: #f3f7f9;
$gray-200: #f7f7f7;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #98a6ad;
$gray-700: #6c757d;
$gray-800: #28282e;
$gray-900: #323a46;
$black:    #000000;


$primary:       #673ab7;
$success:       #10c469;
$info:          #3db9dc;
$danger:        #ff5d48;
$dark:          #28282e;
$light:         #f9f9f9;
$muted:         #7a7d84;

$lightdark:     #e6e6e6;

$font-base :    14px;

$bg-gradient : linear-gradient(to left, #673ab7, #3e1e8c);

$font-family-primary : 'Nunito', sans-serif;
$font-family-secondary: "Cerebri Sans,sans-serif";